window.addEventListener("load", () => {
  if ($('body.home.index').length === 1) {
    window.removeEventListener('resize', setFooterHeight);
  } else {
    setFooterHeight();
    window.addEventListener('resize', setFooterHeight);
  }
});


function setFooterHeight() {
  let bottomPadding = $("footer").css("height");
  $(".main").css("padding-bottom", `calc(${bottomPadding})`);
}