$( document ).on('turbolinks:load', function() {
    var homePage = $('body.index.home').length >= 1;

    if (homePage) {
        $('.social-media .facebook').on('mouseenter', showSocialLinks);
        $('.social-media .facebook').on('mouseleave', hideSocialLinks);
    }
});

function showSocialLinks() {
    $(this).find('.links').show('slow')
}

function hideSocialLinks() {
    $(this).find('.links').hide('slow')
}
