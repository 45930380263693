$( document ).on('turbolinks:load', function() {
  $('#lg-search').on('click', triggerSearchFocus);
  $('#nav-search-form').on('click', handleNavSearchClick);
  $('#nav-search-form input').on('blur', handleNavSearchBlur);
  $('#nav-search-form input').on('input', handleNavSearchInput);
  $('#articles-modal').on('hidden.bs.modal', resetSearch);
})

function triggerSearchFocus() {
  $('#nav-search-form input').focus();
}

const handleNavSearchBlur = e => {
    const input = e.target;

    input.value ? $(input).addClass('active') : $(input).removeClass('active')
};

const handleNavSearchClick = () => $('#nav-search-form input').focus();

const handleNavSearchInput = e => {
    const input = e.target;
    const submitButton = $(input).parents('form').find('button[type="submit"]');
    input.value ? enableButton(submitButton) : disableButton(submitButton)
};

const disableButton = button => $(button).prop('disabled', true);

const enableButton = button => $(button).prop('disabled', false);

function resetSearch() {
  window.searchPage = 1;

  $('#articles-modal .article-list').empty();
}
