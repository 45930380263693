require('./searchForm.js');
require('./featuredContent.js');
require('./article.js');
require('./navbar.js');
require('./home.js');
require('./admin.js');

$( document ).on('turbolinks:load', function() {
  if ($('body.about_aaj').length > 0 || $('body.about_accidents').length > 0) {
    $(".butter img").addClass('img-fluid');
  }

  $('figure.image figcaption').each(function() {
    if($('figure.image img').width() > 0 ){
      $(this).width($('figure.image img').width());
    }else{
      $(this).width("100%");
    }

  });

  $('figure.image').each(function(){
    if($(this).children('img').width() > 0){
      $(this).children('figcaption').width($(this).children('img').width())
    }else{
      $(this).children('figcaption').width("100%")
    }

  })

  //$('#article img[style*="float:left;"]')


});
