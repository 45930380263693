window.searchPage = 1;

$( document ).on("turbolinks:load", function() {
  let homePage = $('body.home.index').length > 0;

  if (homePage) {
    initSearchForm();
    checkAdvSearch();
    addScrollTopListener();
  }
});


$( document ).ready(function() {

  var urlParams = new URLSearchParams(window.location.search);
  if(urlParams.get('adv') == "true"){
    $("#advanced-options").collapse();
  }

});


function initSearchForm() {
  let advOptions = $("#advanced-options");
  advOptions.on('hide.bs.collapse', function() {
    $("#toggle-adv-search small").text("Show advanced search options");
    $('#toggle-adv-search .fa-angle-down').removeClass('hide');
  });

  advOptions.on('show.bs.collapse', function() {
    $("#toggle-adv-search small").text("Hide advanced search options");
    $('#toggle-adv-search .fa-angle-down').addClass('hide');
  });

  // add reset button functionality
  $(".reset-btn button[type='reset']").click(function(){
    $("input[name='search[keywords]']").value();
    $("input[name='search[year]']").value();
    $("input[name='search[author]']").value();
    $("input[name='search[type]']").value();
  });

  // reset page number on submit
  $( document ).on('click', "#home-search", function() { window.searchPage = 1; });

  // add event listener for sort
  $(document).on('change', '#sort-results', function() {
    window.searchPage = 1;
    $.ajax({
      method: 'GET',
      url: '/articles/search.js',
      data: { search:
        {
          keywords: $("input[name='search[keywords]']").val(),
          year: $("input[name='search[year]']").val(),
          author: $("input[name='search[author]']").val(),
          type: $("input[name='search[type]']").val(),
          order: $("#sort-results").val(),
          publication: ($('#aaj').is(':checked')) ? 'aaj': null,
          anac: ($('#anac').is(':checked')) ? 'anac': null,
        },
      }
    })
  });
}

function checkAdvSearch() {
  let urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has('adv_search')) {
    document.getElementById('toggle-adv-search').click();
  }
}

function addScrollTopListener() {
  $(document).on('click', '#scroll-top', function() {
    $('html, body').animate({scrollTop: $('.root-container').offset().top}, 'slow')
  })
}

