$( document ).on('turbolinks:load', function() {
  if ($('body.articles.show').length > 0) {
    resizeImages();
    $('#image-gallery .carousel-indicators li').first().addClass('active');
    $('#image-gallery .carousel-item').first().addClass('active');
    $('#image-gallery .carousel').carousel({
      interval: 8000
    });

    $(document).on('click', '.show-arrow', rotate180);
    $(document).on('click','.fs-show-arrow', fsRotate180);
    $('#toggle-fs').on('click', initFullscreenGallery);
    $('#play-pause').on('click', togglePlay);
    $('#fs-img-gallery').on('hide.bs.modal', function () {
      $('#full-image-gallery .carousel-item').removeClass('active');
      $('#full-image-gallery .carousel-indicators li').removeClass('active');
    })
  }
});

function resizeImages() {
  $("#article img").addClass('img-fluid');
}

function rotate180() {
  let caption = $('#caption');
  let captionShown = caption.hasClass('show');
  let collapsing = caption.hasClass('collapsing');

  if (collapsing) {
    return null
  } else if (!captionShown) {
    $('.show-arrow').css('transform', 'rotate(180deg)')
  } else {
    $('.show-arrow').css('transform', 'rotate(0deg)')
  }
}

function fsRotate180() {
  let fsCaption = $('#fs-caption');
  let captionShown = fsCaption.hasClass('show');
  let collapsing = fsCaption.hasClass('collapsing');

  if (collapsing) {
    return null
  } else if (!captionShown) {
    $('.fs-show-arrow').css('transform', 'rotate(180deg)')
  } else {
    $('.fs-show-arrow').css('transform', 'rotate(0deg)')
  }
}

function initFullscreenGallery() {
  $('#image-gallery').carousel('pause');
  $('#full-image-gallery .carousel-indicators li').first().addClass('active');
  $('#full-image-gallery .carousel-item').first().addClass('active');
  $('#full-image-gallery .carousel').carousel()
}

function togglePlay() {
  let playButton = $('#play-pause .fas');
  let playing = playButton.hasClass('fa-pause');

  if (playing) {
    playButton.removeClass('fa-pause');
    playButton.addClass('fa-play');
    $('#play-pause').attr('title', 'Play');
    $('#image-gallery').carousel('pause')
  } else {
    playButton.removeClass('fa-play');
    playButton.addClass('fa-pause');
    $('#play-pause').attr('title', 'Pause');
    $('#image-gallery').carousel('cycle')
  }
}
