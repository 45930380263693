$( document ).on('turbolinks:load', function() {
  $( document ).on('click', '#add-ancestry', addAncestryToForm);
  $( document ).on('change', '.area-select', getSubAreas);
  if ($('body.articles.edit').length >= 1) {
    initTinyMCE();
  }
})

function initTinyMCE() {
  let imageList = JSON.parse($('.attr-passer').attr('data-attrs'))

  tinymce.init({
    selector: '.tinymce',
    height: 800,
    toolbar: 'undo redo | link table | bold italic underline strikethrough subscript superscript charmap | styleselect | alignleft aligncenter alignright alignjustify outdent indent blockquote numlist bullist | code visualblocks media image',
    plugins: 'code table link charmap lists autoresize visualblocks media paste image',
    a11y_advanced_options: true,
    // content_style: 'figure.image img { float: unset; }',
    formats: {
      removeformat: [
        {
          selector: 'h1,h2,h3,h4,h5,h6',
          remove: 'all',
          split: false,
          expand: false,
          block_expand: true,
          deep: true
        },
        {
          selector: 'p',
          remove: 'none',
          deep: true
        },
        {
          selector: 'span',
          remove: 'none',
          deep: true
        },
        {
          selector: 'a,b,strong,em,i,font,u,strike,sub,sup,dfn,code,samp,kbd,var,cite,mark,q,del,ins,div',
          remove: 'all',
          split: true,
          expand: false,
          deep: true
        }
      ]},
    paste_as_text: true,
    image_list: imageList,
    image_caption: true,
    image_dimensions: false,
    style_formats: [
      { title: 'Image Formats' },
      { title: 'Image Left', selector: 'img', styles: { 'float': 'left', 'margin': '12px 12px 12px 0' } },
      { title: 'Image Right', selector: 'img', styles: { 'float': 'right', 'margin': '12px 0 12px 12px' } }
    ]
  })

  $('figure.image').width($('figure.image img').width());
};

function getSubAreas(e) {
  e.preventDefault();

  let value = e.target.value;
  let target = e.target;

  $.ajax({
    method: 'GET',
    url: '/areas/get_areas',
    dataType: 'json',
    data: { areas: value }
  }).done(data => handleNewValue(data, target, value))
}

var selectedArea;
// var ancestry_array = [];
var ancestry_string;

function handleNewValue(data, target, value) {
  if(data.length > 0){
    let areaOptions = data.map(area => `<option value='${area.id}'>${area.name} ${area.id}</option>`)

    // remove subsequent siblings
    $(target).nextAll().remove();

    // add next child dropdown
    $(target).after(`<select class='area-select custom-select' data-id='${value}'><option></option>${areaOptions.toString()}</select>`)
  }
  build_ancestry_string(value);
}

function build_ancestry_string(val){
  var ancestry_array = [];
  $(".area-select").each(function(){
    ancestry_array.push($(this).val());
  });
  ancestry_string = ancestry_array.join("/").replace(/\/\s*$/, "");

  //only update the form with the last integer in the list... i.e. article connectd to area
  if ($('body.articles.edit').length >= 1 || $('body.articles.new').length >= 1) {
    selectedArea = val;
  }else if ($('body.areas.edit').length >= 1 || $('body.areas.new').length >= 1) {
    selectedArea = ancestry_string;
  }

  $('#ancestry_string').html(selectedArea);
}

function addAncestryToForm() {
  $('#article_area_id').val(selectedArea); ///when updating area
  $('#area_ancestry').val(selectedArea);   //when updating article

  $('#ancestry-modal').modal('hide');
}
