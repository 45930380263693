$(document).on("turbolinks:load", function(event) {
  var homePage = $('body.home.index').length > 0;

  if (homePage) {
    initFeaturedContent()
  }
})

function initFeaturedContent() {
  getContent()
  handleClick()
}

getContent = () =>
  $.get({
    url: '/articles/featured_content'
  }).done(content => renderContent(content))
    .fail((jXHR, status, error) => console.log('error getting content', error))

function renderContent(content) {
  let contentList = content.map(slide => {
  return `<li class='slide'><a href='articles/${slide.article.id}'><div class='background-img' style='background-image: url(${slide.asset.filename.url});'></div><p class='mb-0 p-3'><small>${slide.article.pub_title}</small><br/><small>By ${slide.article.author_first} ${slide.article.author_last}</small></p></a></li>`
  }) 
  
  contentList.forEach( slide => $("#light-slider").append(slide))

  initLightSlider()
}

function initLightSlider() {
  $('#light-slider').lightSlider({
    item: 6,
    loop: true,
    pager: false,
    slideMargin: 20,
    responsive : [
      {
        breakpoint:960,
        settings: {
          item:4,
          slideMove:1,
          slideMargin:6,
        }
      },
      {
        breakpoint:800,
        settings: {
          item:3,
          slideMove:1,
          slideMargin:6,
        }
      },
      {
        breakpoint:480,
        settings: {
          item:2,
          slideMove:1
        }
      }
    ]
  })
}

handleClick = () =>
  $('.tab').click(function() {
    $('html, body').animate({ scrollTop: $('.slider').offset().top - 60 }, 'slow')
  })
